<template>
  <div class="sidebar">
    <ul class="nav">
      <li class="nav-header">Navigation</li>
      <li
        v-for="tab in tabs"
        :key="tab.text"
        :class="{ 'active': value && value.text === tab.text }"
        @click="selectTab(tab)"
      >
        <a href="javascript:;">
          <i class="material-icons">{{ tab.icon }}</i>
          <span>{{ tab.text }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AdminSidebar',
  props: ['value', 'tabs'],
  components: {},
  data() {
    return {};
  },
  methods: {
    selectTab(tab) {
      this.$emit('input', tab);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
